<template>
  <el-dialog title="报案历史"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="85%"
    :before-close="handleClose">
    <div>
      <div style="display: flex;justify-content: flex-end;">
        <span style="font-weight: bold;">赔付总金额：{{indemnityNum}}元</span>
        <span style="font-weight: bold;margin-left: 10px;">满期赔付率：{{indemnityRate}}%</span>
      </div>
      <el-table :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection"
          width="100"></el-table-column> -->
        <el-table-column type="index"
          label="序号"
          width="50"></el-table-column>
        <el-table-column prop="realCaseNo"
          label="报案号">
          <template slot-scope="{row}">
            <!-- <div class="el-button polecy-button el-button--text el-button--small"
              style="text-decoration:underline;padding: 0;"
              @click="handleGoPath(row)">{{ row.realCaseNo }}</div> -->
            {{ row.realCaseNo }}
          </template>
        </el-table-column>
        <el-table-column prop="reportCasePersonName"
          label="报案人姓名"></el-table-column>
        <el-table-column prop="reportCaseTime"
          label="报案时间"></el-table-column>
        <el-table-column prop="statusDesc"
          label="案件状态"></el-table-column>
        <el-table-column prop="realCompensationAmount"
          label="赔付金额">
          <template slot-scope="scope">
            <span v-if="scope.row.statusDesc === '已接案' || scope.row.statusDesc === '验标中' || scope.row.statusDesc === '定损中' || scope.row.statusDesc === '核赔中' || scope.row.statusDesc === '理算中'">{{scope.row.estimateLoss}}(预估)</span>
            <span v-else>{{scope.row.totalPayAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="caseFollower"
          label="案件负责人"></el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
        @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { insClaimsCasePage, insClaimsCaseSumRCA } from "@/api/claimData";
export default {
  props: {
    showDialog: Boolean,
    formData: {
      type: Object,
      default: () => { }
    },
    totalPremium: {
      type: [Number, String],
      default: 0
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formInline = {
            policyNo: '',
            policyHolder: '',
            insured: '',
            frameNo: ''
          };
          this.getTableData();
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      formInline: {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      },
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      oldFormInline: {},
      indemnityNum: 0,
      indemnityRate: 0
    };
  },
  methods: {
    getTableData(e) {
      insClaimsCasePage({
        current: this.current,
        size: this.size,
        ...this.formData
      }).then(res => {
        if (res.data && res.data.records && res.data.records.length) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
        }
      }).catch(err => {
        this.tableData = [];
      });
      insClaimsCaseSumRCA({
        ...this.formData
      }).then(res => {
        this.indemnityNum = Number(res.data.sumRCA);
        this.indemnityRate = Number(res.data.compensateRate);
      }).catch(err => {
        console.log(err);
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    onReset() {
      this.current = 1;
      this.formInline = {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      };
      this.getTableData();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getTableData('current');
    },
    handleSizeChange(e) {
      this.size = e;
      this.getTableData('size');
    },
    handleSelectionChange(e) {
      console.log(e);
      if (e.length) {
        this.fileList = [e[e.length - 1]];
      }
      if (e.length > 1) {
        // this.$message.error('只能选择一个保单！');
        const id = e[e.length - 1].id;
        const index = this.tableData.findIndex(val => val.id == id);
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(this.tableData[index], true);
      }
    },
    handleGoPath(e) {
      console.log(e);
      this.$router.push({ path: '/routerPolicyManagement/ConstructionMachinery/policyDetails/' + e.policyNo });
    }
  }
};
</script>

<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
}
</style>